import { Box, Button, VStack } from '@chakra-ui/react';
import { isToday } from 'date-fns';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { useACIGate, useAciMachine } from '@arena-labs/strive2-data';
import { TEXT, useResponsive, WaitForQuery } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

import { ImageAndGradientBackground } from '../practice/practice-detail/image-and-gradient-background';

export function AciCTACard() {
  const { actions } = useACIGate();
  const userQuery = $API.useGetUserProfile();
  const rs = useResponsive();
  const analytics = useAnalytics();
  const [state, send] = useAciMachine();
  const { aciState, aciResponse } = state.context;

  const isComplete =
    aciResponse &&
    isToday(aciResponse.created_at) &&
    !aciResponse.skipped &&
    Boolean(aciResponse.daily);

  const isSkipped = aciResponse?.skipped || aciState.skipped;

  const canResume = !isSkipped && !isComplete && aciState.recharge_estimate;

  return (
    <Box
      w={'full'}
      layerStyle={'1dp'}
      rounded={'card'}
      position={'relative'}
      pb={5}
      zIndex={0}
    >
      <ImageAndGradientBackground
        image={'./images/aci-card-lines.webp'}
        gradient={
          'linear-gradient(180deg, rgba(45, 45, 45, 0) 0%, #282828 39.85%)'
        }
      />

      <VStack
        px={4}
        gap={3}
        pt={rs({
          xs: '40px',
          base: isSkipped || isComplete ? '40px' : '100px',
        })}
        textAlign={'left'}
        w={'full'}
        zIndex={3}
      >
        {isSkipped ? (
          <>
            <TEXT.H2 color={'neutral.white'} mr={'auto'}>
              Arena Check-In skipped
            </TEXT.H2>
            <TEXT.P2 lineHeight={'tall'} color={'neutral.400'}>
              You decided to skip today. Come back tomorrow for a new check-in!
            </TEXT.P2>
          </>
        ) : isComplete ? (
          <>
            <TEXT.H2 color={'neutral.white'} mr={'auto'}>
              Arena Check-In completed
            </TEXT.H2>
            <WaitForQuery query={userQuery}>
              {(user) => {
                return (
                  <Button
                    variant={'underline-link'}
                    color={'white'}
                    onClick={() => {
                      user.profile.wearable[0]?.provider
                        ? send({ type: 'open' })
                        : actions.openToResults();
                      analytics.logEvent(DataTrackingEvent.ACIInsightsOpened);
                    }}
                  >
                    <TEXT.P1>View today&apos;s insights</TEXT.P1>
                  </Button>
                );
              }}
            </WaitForQuery>
          </>
        ) : (
          <>
            <TEXT.H2 lineHeight={0} color={'neutral.white'} mr={'auto'}>
              Arena Check-In
            </TEXT.H2>
            <TEXT.P2 lineHeight={'tall'} color={'neutral.400'}>
              Build Mind-Body Interoception through Daily Check-Ins.{' '}
            </TEXT.P2>
            <WaitForQuery query={userQuery}>
              {(user) => {
                return (
                  <>
                    <Button
                      w={'full'}
                      variant={'primary'}
                      onClick={() => {
                        if (user.profile.wearable[0]?.provider) {
                          send({ type: 'open' });
                          analytics.logEvent(DataTrackingEvent.ACIModalOpened);
                        } else {
                          actions.handleStartCheckIn();
                        }
                      }}
                    >
                      {canResume ? 'Resume' : 'Start'}
                    </Button>
                  </>
                );
              }}
            </WaitForQuery>
            <Button
              variant={'underline-link'}
              color={'white'}
              onClick={() => {
                analytics.logEvent(DataTrackingEvent.ACISkipped);
                send({ type: 'skip' });
              }}
            >
              <TEXT.P1>Skip check-in and reveal data</TEXT.P1>
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
}
