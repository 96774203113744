import { assign, setup } from 'xstate5';

import { StriveApiResponse } from '@strive/api';

export const challengeSelectionMachine = setup({
  types: {
    context: {} as {
      selectedChallenge: StriveApiResponse<'getPillars'>[number];
    },
    events: {} as
      | { type: 'Click View All' }
      | {
          type: 'User Select Challenge From Full List';
          selectedChallenge:
            | StriveApiResponse<'getPillars'>[number]
            | undefined;
        }
      | { type: 'Go Back' },
  },
  actions: {
    'Save Challenge In Context': assign(({ context, event }) => {
      if (event.type === 'User Select Challenge From Full List') {
        return { selectedChallenge: event.selectedChallenge };
      }
      return context;
    }),
  },
}).createMachine({
  context: {
    selectedChallenge: {} as StriveApiResponse<'getPillars'>[number],
  },
  id: 'Untitled',
  initial: 'shortList',
  states: {
    shortList: {
      on: {
        'Click View All': {
          target: 'fullList',
        },
      },
    },
    fullList: {
      on: {
        'User Select Challenge From Full List': {
          target: 'challengeSelectedFromFullList',
          actions: 'Save Challenge In Context',
        },
        'Go Back': {
          target: 'shortList',
        },
      },
    },
    challengeSelectedFromFullList: {
      on: {
        'Go Back': {
          target: 'fullList',
        },
      },
    },
  },
});
