import { useState } from 'react';
import { Box, Button, Flex, Grid, Icon } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';
import { useSwiper } from 'swiper/react';

import {
  AlertBox,
  ChevronLeftIcon,
  TEXT,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $API, WearableProviders } from '@strive/api';

import { AddressForm } from '../address-form';
import {
  ShippingInformation,
  ShippingInformationEntry,
} from '../shipping-information';

export function ConfirmOrEditAddress({
  ringSize,
  wearableProvider,
  ringStyle,
}: {
  ringStyle?: string;
  ringSize: number;
  wearableProvider: WearableProviders;
}) {
  const swiper = useSwiper();
  const [view, setView] = useState<'reviewAddress' | 'setAddress'>(
    'reviewAddress',
  );

  const wearableOrderQuery = $API.useGetWearableOrder();
  const updateRingSize = $API.useSetWearableOrder(
    {},
    {
      onSuccess: () => {
        swiper.slideNext();
      },
      onError: (e) =>
        captureException(e, {
          data: 'Ring Order Confirmation Failed, Confirm Or Edit Address View',
        }),
    },
  );

  return (
    <Flex
      h={'100vh'}
      direction={'column'}
      px={6}
      pt={'env(safe-area-inset-top) + 24px'}
    >
      <Grid
        templateColumns={'40px 1fr 40px'}
        alignItems={'center'}
        color={'logo'}
        textAlign={'center'}
        py={3}
      >
        <Flex as={'button'} onClick={() => swiper.slidePrev()}>
          <Icon as={ChevronLeftIcon} boxSize={4} />
        </Flex>
        <TEXT.H3>Ring Order Confirmation</TEXT.H3>
      </Grid>

      <Flex direction={'column'} gap={10} pt={10} overflowY={'scroll'}>
        <Flex direction={'column'} gap={2}>
          <TEXT.H2 color={'neutral.white'}>
            Confirm Ring Size and Shipping Address
          </TEXT.H2>
          <TEXT.P1 color={'neutral.300'}>
            Please review the provided ring size and shipping address. If
            everything looks good, we&apos;ll place the order!
          </TEXT.P1>
        </Flex>

        <Box>
          <TEXT.P1_SEMIBOLD mb={2}>
            {wearableProvider === 'striveware'
              ? 'StriveWare Details'
              : 'Oura Ring Details'}
          </TEXT.P1_SEMIBOLD>
          <Flex
            p={3}
            layerStyle={'1dp'}
            shadow={'none'}
            rounded={'md'}
            border={'1px solid'}
            borderColor={'neutral.900'}
            direction={'column'}
          >
            {ringStyle && (
              <ShippingInformationEntry
                label={'Color'}
                entryLines={[ringStyle]}
                mb={4}
              />
            )}
            <ShippingInformationEntry
              label={'Size'}
              entryLines={[
                wearableProvider === 'striveware'
                  ? `US ${ringSize}`
                  : `${ringSize}`,
              ]}
            />
          </Flex>
          {updateRingSize.isError ? (
            <AlertBox
              textAlign={'left'}
              mt={4}
              status="error"
              title={'Oops!'}
              description={
                'We could not update your ring size at this time. Please, try again!'
              }
            />
          ) : null}
        </Box>

        <WaitForQuery query={wearableOrderQuery}>
          {(data) => (
            <>
              {data.address && view === 'reviewAddress' && (
                <Box pb={100}>
                  <ShippingInformation
                    onEdit={() => setView('setAddress')}
                    address={data.address}
                  />

                  <Box
                    px={6}
                    pt={4}
                    position={'absolute'}
                    bottom={0}
                    left={0}
                    w={'full'}
                    layerStyle={'0dp'}
                    pb={'calc(env(safe-area-inset-bottom) + 24px)'}
                  >
                    <Button
                      isLoading={updateRingSize.isLoading}
                      variant={'primary'}
                      w={'full'}
                      onClick={() => {
                        updateRingSize.mutate({
                          size: ringSize,
                          address: data.address,
                          style: ringStyle,
                        });
                      }}
                    >
                      Confirm and Ship My Ring
                    </Button>
                  </Box>
                </Box>
              )}
              {(!data.address || view === 'setAddress') && (
                <Box pb={200}>
                  <TEXT.P1_SEMIBOLD mb={'4'}>
                    Shipping Information
                  </TEXT.P1_SEMIBOLD>
                  <AddressForm
                    defaultValues={data?.address ?? {}}
                    onSuccess={() => setView('reviewAddress')}
                  />
                </Box>
              )}
            </>
          )}
        </WaitForQuery>
      </Flex>
    </Flex>
  );
}
