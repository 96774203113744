import { useEffect } from 'react';
import { useMount } from 'react-use';
import NextLink from 'next/link';
import { Box, chakra, Flex, HStack, Link, Spacer } from '@chakra-ui/react';
import { useAnimate } from 'framer-motion';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { Elevation, TEXT, useResponsive } from '@arena-labs/strive2-ui';
import { haptics } from '@strive/device';

import { AciCtaWidget } from '../aci/aci-cta-widget';
import { LogPracticeCard } from '../practice/log-practice-card';
import PillarCompleteModal from './practice-set-complete/pillar-complete-modal';

export type PracticeFreeSpaceProps = {
  animate?: boolean;
  onAnimationComplete?: () => unknown;
};

export default function PracticeFreeSpace({
  animate = true,
  onAnimationComplete,
}: PracticeFreeSpaceProps) {
  const [homepage, { state, send }] = useHomepage();
  const learningSession = homepage?.learning_session;
  const rs = useResponsive();
  const explorationsStartsTomorrow =
    learningSession?.is_final_session_within_pillar &&
    learningSession.next_pillar_title === null;

  const pillarComplete = PillarCompleteModal.useDisclosure();
  const [scope, animates] = useAnimate();

  useEffect(() => {
    animate &&
      animates([
        [
          '#logPractice',
          { y: ['200px', '0px'], opacity: [0, 1] },
          { duration: 0.75, ease: 'easeOut', delay: 1 },
        ],
      ]).then(() => onAnimationComplete && onAnimationComplete());
  }, [animates, animate, onAnimationComplete]);

  useMount(() => {
    if (animate) {
      setTimeout(() => haptics.notification('SUCCESS'), 1000);
    }
  });

  const todaysTool = learningSession?.practices.at(-1);
  return (
    <Box mx={-6} h="full" ref={scope} overflowY={'hidden'}>
      <Flex
        px={6}
        direction={'column'}
        gap={8}
        w="full"
        h="full"
        textAlign={'center'}
        pb="2"
        backgroundPosition={rs({ xs: 'center', base: 'bottom' })}
        backgroundSize={'cover'}
        bgImage={
          explorationsStartsTomorrow
            ? './images/celebration-background.webp'
            : 'none'
        }
      >
        {explorationsStartsTomorrow && (
          <Elevation
            level={'0dp'}
            px={2}
            py={4}
            as={Flex}
            direction={'column'}
            gap={6}
            mt={3}
          >
            <TEXT.H2 as={Flex} flexDirection={'column'}>
              <span>Strive Foundations</span>
              <span>Completed!</span>
            </TEXT.H2>
            <TEXT.H3 color={'neutral.400'}>
              Tomorrow, you will enter a self-guided journey called the{' '}
              <chakra.span color={'secondary.400'}>
                Strive Explorations
              </chakra.span>
              . Don&apos;t miss out!
            </TEXT.H3>
          </Elevation>
        )}

        <Flex
          id={'logPractice'}
          flexDirection="column"
          gap="4"
          textAlign={'left'}
        >
          <HStack>
            <TEXT.P1_SEMIBOLD textAlign="start">Today’s Tool</TEXT.P1_SEMIBOLD>
            <Spacer />
            <Link
              as={NextLink}
              variant={'underline'}
              href="/practices"
              color={'neutral.white'}
              fontSize={'p2'}
            >
              View All
            </Link>
          </HStack>

          {todaysTool ? (
            <LogPracticeCard practice={todaysTool} highlightChecked />
          ) : null}

          <AciCtaWidget />
        </Flex>

        <PillarCompleteModal
          pillarTitle={homepage?.pillar_data?.current_pillar.title}
          pillarIconPath={homepage?.pillar_data?.current_pillar.icon}
          explorationsStartsTomorrow={explorationsStartsTomorrow}
          isOpen={state.matches('Pillar Complete Modal.open')}
          onClose={() => {
            send('Close Pillar Complete Modal');
            pillarComplete.onClose();
          }}
        />
      </Flex>
    </Box>
  );
}
