import { Box, Button, Grid, Image, Text } from '@chakra-ui/react';

import { WaitForQuery } from '@arena-labs/strive2-ui';
import { StriveApiResponse } from '@strive/api';

import { RecommendedFlag } from './recommended-flag';
import { useAvailablePracticeSets } from './use-available-practice-sets';

export function ViewAllChallenges({
  onClick,
}: {
  onClick: (challenge: StriveApiResponse<'getPillars'>[number]) => void;
}) {
  const practiceSetQuery = useAvailablePracticeSets(20);

  return (
    <WaitForQuery query={practiceSetQuery}>
      {(sets) => {
        const challenges = sets[0].filter((set) => set.state === 'not_started');
        const reccChallenge = challenges[0];
        if (!challenges) {
          return null;
        }
        return (
          <>
            {reccChallenge && (
              <ViewAllSelectionButton
                onClick={() => onClick(reccChallenge)}
                imagePath={reccChallenge.icon}
                shortTitle={reccChallenge.short_title}
                tag={<RecommendedFlag />}
              />
            )}
            <Text mx={'auto'} textStyle={'p2'} color={'neutral.200'}>
              or
            </Text>
            <Grid templateColumns={'1fr 1fr'} columnGap={4} rowGap={6}>
              {sets[0].map((set, index) => {
                if (index !== 0) {
                  return (
                    <ViewAllSelectionButton
                      key={`${index}${set.slug}`}
                      onClick={() => onClick(set)}
                      imagePath={set.icon}
                      shortTitle={set.short_title}
                    />
                  );
                }
              })}
            </Grid>
          </>
        );
      }}
    </WaitForQuery>
  );
}

function ViewAllSelectionButton({
  onClick,
  imagePath,
  shortTitle,
  tag,
}: {
  onClick: () => void;
  imagePath: string;
  shortTitle: string;
  tag?: React.ReactNode;
}) {
  return (
    <Button
      variant={'unstyled'}
      h={'auto'}
      onClick={() => onClick()}
      w={'full'}
      position={'relative'}
    >
      <Box
        w={'full'}
        borderRadius={'card'}
        py={4}
        bgGradient={'linear-gradient(180deg, #315B66 0%, #10272D 100%)'}
      >
        <Image
          boxSize={12}
          src={imagePath}
          color={'logo'}
          mb={2}
          mx={'auto'}
          alt={''}
        />

        <Text textStyle={'P2'} fontWeight={'500'} fontSize={'13px'}>
          {shortTitle}
        </Text>

        {tag && (
          <Box
            position={'absolute'}
            top={0}
            left={0}
            transform={'scale(0.55) translateY(-100%) translateX(-25%)'}
          >
            {tag}
          </Box>
        )}
      </Box>
    </Button>
  );
}
