import {
  CoachMinimumDetail,
  Video,
  VideoPageMetadata,
} from '@arena-labs/shared-models';
import { Program } from '@arena-labs/strive2-content';

/**
 * Get the video detail URL for a video
 */
export function getVideoPage(
  video: Video | string,
  meta?: VideoPageMetadata,
): string {
  const videoSlug = typeof video === 'string' ? video : video.slug;
  const params = new URLSearchParams();
  if (meta?.program) {
    params.append('program', meta.program);
  }
  const queryString = params.toString() ? `?${params.toString()}` : '';
  return `/content/videos/${videoSlug}${queryString}`;
}

/**
 * Get the program detail URL for a program
 */
export function getProgramPage(program: Program): string {
  return `/coaching/programs/${program.slug}`;
}

/**
 * Get the coach detail URL
 */
export function getCoachPage(coach: Pick<CoachMinimumDetail, 'slug'>): string {
  return `/coaching/ambassadors/${coach.slug}`;
}
