import { Box, Circle, Flex, Icon, VStack } from '@chakra-ui/react';

import { isMediaPlayedThisSession } from '@arena-labs/shared-models';
import { useHomepage } from '@arena-labs/strive2-coaching';
import { CheckIcon, TEXT, useGradient } from '@arena-labs/strive2-ui';

export function SessionTracker() {
  const [homepage] = useHomepage();

  if (!homepage?.learning_session?.media_items) {
    return null;
  }

  const mediaItems = homepage?.learning_session?.media_items;

  //grab any loom videos in the current lesson
  const loomVideos = mediaItems.filter(
    (mediaItem) => mediaItem.type === 'loom',
  );

  const completedLooms = loomVideos.filter((loom) =>
    isMediaPlayedThisSession(loom),
  );

  //remove loom videos froms the current lesson
  const mediaWithoutLooms = mediaItems.filter(
    (item) => !loomVideos.includes(item),
  );

  //find the pillar intro withing the media session w/o looms
  const pillarOverview = mediaWithoutLooms.find(
    (media) =>
      (media.type === 'audio' || media.type === 'video') &&
      media.category === 'pillar_intro',
  );

  const pillarOverviewComplete = pillarOverview
    ? isMediaPlayedThisSession(pillarOverview)
    : undefined;

  //grab any videos/audios that are either 'practice_intro' or 'practice_coach_me'
  const lessons = mediaWithoutLooms.filter(
    (media) =>
      (media.type === 'audio' || media.type === 'video') &&
      (media.category === 'practice_intro' ||
        media.category === 'practice_coach_me'),
  );

  const completedLessons = lessons.filter((lesson) =>
    isMediaPlayedThisSession(lesson),
  );

  //match the practice_intro to the practice to make sure the counter is correct
  const introLesson = lessons.find(
    (lesson) =>
      (lesson.type === 'audio' || lesson.type === 'video') &&
      lesson.category === 'practice_intro',
  );
  const introLessonTitle =
    introLesson?.type !== 'loom' ? introLesson?.title : undefined;

  const currentPractice = homepage.learning_session.practices.find(
    (practice) => practice.intro.title === introLessonTitle,
  );
  const currentPracticeLogCount = currentPractice?.log_count;

  return (
    <>
      <Flex direction={'row'} justify={'space-between'} w="full">
        <TrackerCircle title={'ACI'} activated={true} complete={true}>
          <Icon as={CheckIcon} color="logo" boxSize={4} />
        </TrackerCircle>

        {pillarOverview && (
          <TrackerCircle
            title={'Pillar Intro'}
            activated={true}
            complete={
              pillarOverviewComplete !== undefined
                ? pillarOverviewComplete
                : true
            }
          >
            <TEXT.P3>
              {(pillarOverview?.type === 'audio' && pillarOverview.played) ||
              (pillarOverview?.type === 'video' && pillarOverview.watched)
                ? 1
                : 0}
              /1
            </TEXT.P3>
          </TrackerCircle>
        )}

        <TrackerCircle
          title={'Lesson'}
          activated={
            pillarOverviewComplete !== undefined ? pillarOverviewComplete : true
          }
          complete={lessons.length === completedLessons.length}
        >
          <TEXT.P3>
            {completedLessons.length}/{lessons.length}
          </TEXT.P3>
        </TrackerCircle>

        {loomVideos.length ? (
          <TrackerCircle
            title={'Coach Note'}
            activated={lessons.length === completedLessons.length}
            complete={completedLooms.length === loomVideos.length}
          >
            <TEXT.P3>
              {completedLooms.length}/{loomVideos.length}
            </TEXT.P3>
          </TrackerCircle>
        ) : null}

        <TrackerCircle
          title={'Tool'}
          activated={
            loomVideos.length
              ? loomVideos.length === completedLooms.length
              : lessons.length === completedLessons.length
          }
          complete={
            currentPracticeLogCount && currentPracticeLogCount > 0
              ? true
              : false
          }
        >
          <TEXT.P3>
            {currentPracticeLogCount && currentPracticeLogCount > 0 ? 1 : 0}/1
          </TEXT.P3>
        </TrackerCircle>
      </Flex>
    </>
  );
}

type TrackerCirleProps = {
  title: string;
  children: React.ReactNode;
  hasSpacer?: boolean;
  activated: boolean;
  complete: boolean;
};
function TrackerCircle({
  title,
  children,
  activated,
  complete,
}: TrackerCirleProps) {
  const gradient = useGradient('primary.100');

  return (
    <>
      <Box
        my={4}
        w="full"
        h="2px"
        bg={activated ? 'primaryAlpha.60' : 'neutral.700'}
        rounded={'full'}
        sx={{
          ':first-of-type': {
            display: 'none',
          },
        }}
      />
      <VStack
        spacing={1}
        textAlign={'center'}
        color={activated ? 'logo' : 'neutral.700'}
      >
        <Circle
          size={9}
          bg="transparent"
          bgGradient={activated ? gradient : 'none'}
          border={'1px solid'}
          borderColor={
            activated && !complete
              ? 'logo'
              : activated
              ? 'neutral.black'
              : 'neutral.700'
          }
        >
          {children}
        </Circle>
        <TEXT.P3 w="60px">{title}</TEXT.P3>
      </VStack>
    </>
  );
}
