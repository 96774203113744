import { useRouter } from 'next/router';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { groupBy } from 'lodash-es';
import { match, P } from 'ts-pattern';

import {
  ClipboardCheckIcon,
  HandShakeIcon,
  IconProps,
  MailIcon,
  PerfAmbassadorsIcon,
  PlayCircleIcon,
  UserProfileIcon,
  VideosIcon,
} from '@arena-labs/strive2-ui';
import { $API, queryClient, StriveApiResponse } from '@strive/api';
import { useMediaPlayer } from '@strive/av';

import { TypeformModal } from '../home/typeform-modal';
import { ChecklistItem } from './checklist-item';
import CommitmentStatement from './coaching-expectations/commitment-statement';
import { ConfirmEmail } from './confirm-email/confirm-email';
import { LockedChecklistItem } from './locked-checklist-item';
import { SectionHeader } from './section-header';
import {
  LaunchpadChecklistItems,
  LaunchpadWearable,
  TypeformChecklistItems,
} from './types';
import { WearableChecklist } from './wearable-checklist';

export type LaunchpadChecklistProps = {
  launchpad: StriveApiResponse<'getLaunchpad'>;
  reload: () => Promise<void>;
};

export function LaunchpadChecklist({
  launchpad,
  reload,
}: LaunchpadChecklistProps) {
  const { checklist, wearable } = launchpad;
  const groups = groupBy(checklist, 'status');
  return (
    <Flex direction="column" gap="4">
      {groups.active && (
        <ChecklistGroup
          label="Active"
          items={groups.active}
          wearable={wearable}
          onCompleteItem={reload}
        />
      )}

      {groups.completed && (
        <ChecklistGroup
          label="Completed"
          items={groups.completed}
          wearable={wearable}
        />
      )}

      {groups.locked && (
        <ChecklistGroup
          label="Coming Up"
          items={groups.locked}
          wearable={wearable}
        />
      )}
    </Flex>
  );
}

type ChecklistGroupProps = {
  label: string;
  items: NonNullable<LaunchpadChecklistItems>;
  wearable: LaunchpadWearable;
  onCompleteItem?: () => void;
};
function ChecklistGroup({
  label,
  items,
  wearable,
  onCompleteItem,
}: ChecklistGroupProps) {
  return (
    <Flex direction="column" gap="4">
      <SectionHeader label={label} />
      {items.map(
        (item) =>
          item && (
            <LaunchpadChecklistItem
              key={item.slug}
              item={item}
              wearable={wearable}
              onComplete={onCompleteItem}
            />
          ),
      )}
    </Flex>
  );
}

function LaunchpadChecklistItem({
  item,
  wearable,
  onComplete,
}: {
  item: NonNullable<LaunchpadChecklistItems[number]>;
  wearable: LaunchpadWearable;
  onComplete?: () => void;
}) {
  const commitmentStatement = CommitmentStatement.useDisclosure();
  const confirmEmail = ConfirmEmail.useDisclosure();
  const router = useRouter();

  if (item.status === 'locked') {
    return <LockedChecklistItem label={item.label} />;
  }

  return match(item)
    .with(
      { slug: 'setup_wearable', children: P.array(P.any) },
      (setupWearable) =>
        wearable && (
          <WearableChecklist
            list={setupWearable.children}
            wearable={wearable}
            onCompleteItem={onComplete}
            status={setupWearable.status}
          />
        ),
    )
    .with({ typeform_id: P.string, slug: 'pfi_questionnaire' }, (form) => (
      <TypeformChecklistItem
        item={form}
        icon={ClipboardCheckIcon}
        onComplete={onComplete}
        duration={10}
      />
    ))
    .with(
      { typeform_id: P.string, slug: 'build_profile_questionnaire' },
      (form) => (
        <TypeformChecklistItem
          item={form}
          icon={UserProfileIcon}
          onComplete={onComplete}
          duration={2}
        />
      ),
    )
    .with(
      { typeform_id: P.string, slug: 'coach_selection_questionnaire' },
      (form) => (
        <TypeformChecklistItem
          item={form}
          icon={PerfAmbassadorsIcon}
          onComplete={onComplete}
          incompleteLabel={null}
        />
      ),
    )
    .with({ playlist: P.string }, ({ playlist, status, label }) => (
      <ChecklistItem
        icon={VideosIcon}
        status={status}
        label={label}
        onClick={() => router.push(`/launchpad/playlist/${playlist}`)}
        incompleteLabel={null}
      />
    ))
    .with({ type: 'video' }, (task) => (
      <VideoChecklistItem task={task} onComplete={onComplete} />
    ))
    .with({ slug: 'commitment_statement' }, (details) => (
      <>
        <ChecklistItem
          label={details.label}
          icon={HandShakeIcon}
          status={details.status}
          onClick={() => commitmentStatement.onOpen()}
        />
        <CommitmentStatement {...commitmentStatement} />
      </>
    ))
    .with({ slug: 'email_verification' }, (detail) => (
      <>
        <ChecklistItem
          label={detail.label}
          icon={MailIcon}
          status={detail.status}
          onClick={() => confirmEmail.onOpen()}
        />
        <ConfirmEmail {...confirmEmail} />
      </>
    ))
    .exhaustive();
}

function TypeformChecklistItem({
  item,
  onComplete,
  duration,
  icon,
  incompleteLabel,
}: {
  item: TypeformChecklistItems;
  onComplete?: () => void;
  duration?: number;
  icon: IconProps['as'];
  incompleteLabel?: string | null;
}) {
  const disclosure = useDisclosure();
  const saveTypeformResponse = $API.useSetLaunchpadChecklistItemStatus(
    {},
    {
      onSettled: () => {
        queryClient.invalidateQueries();
        onComplete && onComplete();
      },
    },
  );

  return (
    <>
      <ChecklistItem
        key={item.slug}
        label={item.label}
        status={item.status}
        icon={icon}
        duration={duration}
        onClick={disclosure.onOpen}
        incompleteLabel={incompleteLabel}
      />
      {item.status === 'active' && (
        <TypeformModal
          key={`typeform-${item.slug}`}
          allowClose
          title={item.label}
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          formId={item.typeform_id}
          onSubmitted={({ responseId }) => {
            saveTypeformResponse.mutate({
              slug: item.slug,
              status: 'completed',
            });
          }}
        />
      )}
    </>
  );
}

function VideoChecklistItem({
  task,
  onComplete,
}: {
  task: Extract<LaunchpadChecklistItems[number], { type: 'video' }>;
  onComplete?: () => void;
}) {
  const { openPlayer } = useMediaPlayer();
  const markComplete = $API.useSetLaunchpadChecklistItemStatus(
    {},
    {
      onSettled: () => {
        queryClient.invalidateQueries();
        onComplete && onComplete();
      },
    },
  );

  return (
    <ChecklistItem
      label={task.label}
      icon={PlayCircleIcon}
      status={task.status}
      onClick={() =>
        openPlayer(task.video, {
          mode: 'loose',
          onClose: onComplete,
          showEndPrompt: true,
          endPrompt: (props) => (
            <Flex direction="column" gap="4" width="full">
              {props.onReplay && (
                <Button variant="secondary" onClick={props.onReplay}>
                  Watch Again
                </Button>
              )}

              <Button
                variant="primary"
                onClick={async () => {
                  await markComplete.mutateAsync({
                    status: 'completed',
                    slug: task.slug,
                  });
                  props.onContinue();
                }}
                isLoading={markComplete.isLoading || props.continuePending}
              >
                Continue Orientation
              </Button>
            </Flex>
          ),
        })
      }
    />
  );
}
